const AG_GRID_LOCALE = (context) => {
  return {
    // Start of ag-Grid locale translations
    selectAll: context.$gettext('(Select All)'),
    searchOoo: context.$gettext('Search...'),
    blanks: context.$gettext('(Blanks)'),
    noMatches: context.$gettext('No matches'),

    // Number Filter & Text Filter
    filterOoo: context.$gettext('Filter...'),
    equals: context.$gettext('Equals'),
    notEqual: context.$gettext('Not equal'),
    empty: context.$gettext('Choose One'),

    // Number Filter
    lessThan: context.$gettext('Less than'),
    greaterThan: context.$gettext('Greater than'),

    // Text Filter
    contains: context.$gettext('Contains'),

    // Date Filter
    dateFormatOoo: context.$gettext('yyyy-mm-dd'),

    // Filter Buttons
    applyFilter: context.$gettext('Apply'),
    resetFilter: context.$gettext('Reset'),

    // Filter Titles
    textFilter: context.$gettext('Text Filter'),
    numberFilter: context.$gettext('Number Filter'),
    dateFilter: context.$gettext('Date Filter'),
    setFilter: context.$gettext('Set Filter'),

    // Side Bar
    columns: context.$gettext('Columns'),
    filters: context.$gettext('Filters'),

    // Other
    loadingOoo: context.$gettext('Loading...'),
    noRowsToShow: context.$gettext('No Rows To Show'),
    enabled: context.$gettext('Enabled'),
  };
};

export {AG_GRID_LOCALE};
