<template>
  <div class="ag-grid__wrapper">
    <ag-grid-vue
      class="ag-theme-alpine"
      style="width: 100%;"
      :locale-text="localeText"
      :grid-options="gridOptions"
      :column-defs="columnDefs"
      :get-row-style="getRowStyle"
      :default-col-def="defaultColDef"
      @grid-ready="onGridReady"
      @row-clicked="onRowClicked"
      @pagination-changed="paginationChanged" />
    <div class="grid__pagination-cont">
      <div class="grid-left-side__cont">
        <items-amount class="grid__items-per-page"
                      @amountchanged="onPageSizeChanged" />
        <default-sort v-if="showDefaultSort"
                      @defaultsortchanged="onSortChanged" />
      </div>
      <sk-pagination v-if="totalPages && totalPages > 1"
                     :current="currentPage"
                     :total="totalPages"
                     @prev="onBtPrevious"
                     @next="onBtNext"
                     @newpage="toNewPage" />
    </div>
  </div>
</template>

<script>
  import 'ag-grid-community/dist/styles/ag-grid.css';
  import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
  import {AgGridVue} from 'ag-grid-vue';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';
  import DefaultSort from '@/components/shared_components/DefaultSort';
  import {AG_GRID_LOCALE} from '@/components/grid/GridTranslation';

  export default {
    name: 'ag-grid-core',
    components: {
      AgGridVue,
      'items-amount': ItemsAmount,
      'default-sort': DefaultSort
    },
    props: {
      onPaginationChanged: {
        type: Function,
        default: () => {}
      },
      columnDefs: {
        type: Array,
        default: () => []
      },
      gridOptions: {
        type: Object,
        default: () => {}
      },
      fetchDataFunc: {
        type: Function,
        default: () => {}
      },
      successExportRequestFunc: {
        type: Function,
        default: () => {}
      },
      rowStyleFunc: {
        type: Function,
        default: () => {}
      },
      gridData: {
        type: Object,
        default: () => {}
      },
    },
    data() {
      return {
        gridApi: null,
        columnApi: null,
        defaultColDef: {
          // flex: 1,
          minWidth: 120,
          resizable: true,
          floatingFilter: true
        },
        rowData: null,
        firstTimeGridLoad: this.gridData.page > 1,
        // --- Pagination --- //
        currentPage: this.gridData.page || 1,
        totalPages: 10
      };
    },
    computed: {
      // --- Locale --- //
      localeText() { return AG_GRID_LOCALE(this); },
      defaultSortBy() { return this.$store.state.defaultSortBy; },
      paginationGetCurrentPage() {
        return this.gridApi.paginationGetCurrentPage() + 1;
      },
      showDefaultSort() { return this.gridData.withDefaultSort; }
    },
    methods: {
      onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        // ---Set default sort ---//
        if (this.columnApi.getColumn(this.defaultSortBy)) {
          if (this.defaultSortBy === 'id') this.columnApi.getColumn(this.defaultSortBy).setSort('desc');
          else this.columnApi.getColumn(this.defaultSortBy).setSort('asc');
        }
        this.gridApi.showLoadingOverlay();

        this.currentPage = this.paginationGetCurrentPage;
        this.totalPages = this.gridApi.paginationGetTotalPages();

        let preFilledFilterModel = this.gridApi.getFilterModel();
        preFilledFilterModel = {...preFilledFilterModel,
                                ...this.gridData.filterParams};
        this.gridApi.setFilterModel(preFilledFilterModel);

        const dataSource = {
          getRows: (params) => {
            this.gridApi.showLoadingOverlay();
            params.page = this.currentPage;
            params.items = this.paginationGetPageSize();
            params.ransackParams = this.gridData.ransackParams;
            params.firstTimeGridLoad = this.firstTimeGridLoad;

            this.fetchDataFunc(params).then((response) => {
              params.successCallback(response.data, response.count);
              if (response.data && !response.data.length) {
                this.gridApi.showNoRowsOverlay();
                this.firstTimeGridLoad = false;
              } else if (response.mockToAvoidGridFirstRequest) {
                this.gridApi.paginationGoToPage(this.gridData.page - 1);
                this.firstTimeGridLoad = false;
              } else {
                this.firstTimeGridLoad = false;
                this.gridApi.hideOverlay();
              }
            });
          },
        };
        params.api.setDatasource(dataSource);
      },
      getRowStyle(params) {
        // -----default margin for rounded rows--------//
        const rowStyle = {
          'margin-top': '4px',
          ...this.rowStyleFunc(params)
        };
        return rowStyle || undefined;
      },
      onPageSizeChanged() {
        const items = this.$store.state.filterItemsAmount;
        this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', this.$store.state.filterItemsAmount);
        this.gridApi.paginationSetPageSize(Number(items));
        this.updateGridData({ransackParams: this.gridData.ransackParams, pageSize: this.$store.state.filterItemsAmount});
      },
      clearFilters() {
        this.gridApi.setFilterModel(null);
      },
      clearSorting() {
        this.columnApi.applyColumnState({
          defaultState: {sort: null},
        });
      },
      onSortChanged() {
        this.clearSorting();
        const ascendingSortColumnIds = ['startTime'];
        this.columnApi.applyColumnState({
          state: [{
            colId: this.defaultSortBy,
            sort: ascendingSortColumnIds.includes(this.defaultSortBy) ? 'asc' : 'desc'
          }]
        });
      },
      updateGridData({ransackParams, pageSize, resetFilters = false}) {
        this.clearData();
        if (resetFilters) this.clearFilters();
        this.gridApi.showLoadingOverlay();
        const dataSource = {
          getRows: (params) => {
            this.gridApi.showLoadingOverlay();
            params.page = this.currentPage;
            params.items = pageSize || this.paginationGetPageSize();
            params.ransackParams = ransackParams;
            this.fetchDataFunc(params).then((response) => {
              params.successCallback(response.data, response.count);
              if (response.data && !response.data.length) this.gridApi.showNoRowsOverlay();
              else this.gridApi.hideOverlay();
            });
          },
        };
        this.gridApi.setDatasource(dataSource);
      },
      sendExportRequest({ransackParams}) {
        const filterModel = this.gridApi.getFilterModel();
        const sortModel = this.columnApi.getColumnState().filter((column) => column.sort);
        const params = {};
        params.ransackParams = ransackParams;
        params.filterModel = filterModel;
        params.sortModel = sortModel;
        params.page = this.currentPage;
        params.items = this.paginationGetPageSize();
        this.fetchDataFunc(params).then(() => {
          this.successExportRequestFunc();
        });
      },
      clearData() {
        this.gridApi.showLoadingOverlay();
        const dataSource = {
          getRows: (params) => {
            this.gridApi.showLoadingOverlay();
            params.successCallback([], 0);
            this.gridApi.hideOverlay();
          },
        };
        this.gridApi.setDatasource(dataSource);
      },
      paginationChanged() {
        if (this.gridApi) {
          this.currentPage = this.paginationGetCurrentPage;
          this.totalPages = this.paginationGetTotalPages();
          this.onPaginationChanged();
        }
      },
      paginationGetPageSize() {
        return this.gridApi.paginationGetPageSize();
      },
      paginationGetTotalPages() {
        return this.gridApi.paginationGetTotalPages();
      },
      paginationIsLastPageFound() {
        return this.gridApi.paginationIsLastPageFound();
      },
      onBtNext() {
        this.gridApi.paginationGoToNextPage();
      },
      onBtPrevious() {
        this.gridApi.paginationGoToPreviousPage();
      },
      toNewPage(page) {
        this.gridApi.paginationGoToPage(page - 1);
      },
      onRowClicked(event) {
        this.$emit('row-clicked', event);
      },
      emitInterface() {
        this.$emit('interface', {
          updateData: ({ransackParams, resetFilters}) => this.updateGridData({ransackParams, resetFilters}),
          sendExportRequest: ({ransackParams}) => this.sendExportRequest({ransackParams})
        });
      }
    },
    mounted() {
      this.currentPage = this.gridData.page;
      this.emitInterface();
    },
  };
</script>

<style>
.ag-theme-alpine .ag-root-wrapper {
  border: none;
}

/* Style grid column header */
.ag-theme-alpine .ag-header {
  border-bottom: none;
  border-radius: 8px;
  background-color: #f4f5f7;
}

.ag-theme-alpine .ag-header .ag-header-row-column {
  color: #424242;
  font-weight: 400;
}

/* Style grid floating filter header */
.ag-theme-alpine .ag-row {
  margin: 0 4px;
  border: none;
  border-radius: 8px;
}

.ag-theme-alpine {
  --ag-odd-row-background-color: #fff;
}

/* ------ Reset default grid hover background add outline ----- */
.ag-row-hover:hover {
  background-color: transparent;
  outline: 2px solid var(--color-secondary);
}

/* Filter popup style */
.ag-theme-alpine .ag-simple-filter-body-wrapper {
  padding: 10px;
}

/* Filter popup select styles */
.ag-theme-alpine .ag-select:not(.ag-cell-editor) {
  height: 50px;
}

.ag-theme-alpine .ag-menu {
  border-radius: 8px;
}

/* Match Input Style to sk-input */
.ag-theme-alpine input[class^=ag-]:not([type]),
.ag-theme-alpine input[class^=ag-][type=text],
.ag-theme-alpine input[class^=ag-][type=number],
.ag-theme-alpine input[class^=ag-][type=tel],
.ag-theme-alpine input[class^=ag-][type=date],
.ag-theme-alpine input[class^=ag-][type=datetime-local],
.ag-theme-alpine textarea[class^=ag-],
.ag-theme-alpine .ag-picker-field-wrapper {
  width: 100%;
  height: 35px;
  padding: 0 10px;
  border: 1px solid #d3d5de;
  border-radius: 0;
  background-color: #fff;
  font-weight: normal;
  font-size: 14px;
  text-align: inherit;
  caret-color: #646d8c;
}

.ag-theme-alpine input[class^=ag-]:not([type]):focus,
.ag-theme-alpine input[class^=ag-][type=text]:focus,
.ag-theme-alpine input[class^=ag-][type=number]:focus,
.ag-theme-alpine input[class^=ag-][type=tel]:focus,
.ag-theme-alpine input[class^=ag-][type=date]:focus,
.ag-theme-alpine input[class^=ag-][type=datetime-local]:focus,
.ag-theme-alpine textarea[class^=ag-]:focus {
  border: 1px solid #646d8c;
  box-shadow: 0 0 0 1px #646d8c;
  outline: none;
}

/* sk-btn styles */
.ag-theme-alpine .ag-standard-button {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 35px;
  padding: 0;
  border: 1px solid var(--color-secondary);
  border-radius: 3px;
  background-color: #fff;
  background-clip: border-box;
  color: #ff5b24;
  outline: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

/* sk-btn hover styles */
.ag-theme-alpine .ag-standard-button:hover {
  border: 1px solid transparent;
  background-color: #ffa078;
  background-clip: padding-box;
  color: #ff5b24;
}

/* override header cell paddings */
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-right: 10px;
  padding-left: 10px;
}

/* Override hidden overflow for floating filter custom drop-downs */
.ag-theme-alpine .ag-floating-filter-full-body,
.ag-theme-alpine .ag-header-viewport,
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-row,
.ag-theme-alpine .ag-root.ag-layout-normal,
.ag-theme-alpine .ag-root-wrapper,
.ag-theme-alpine .ag-root.ag-layout-auto-height {
  overflow: visible !important;
}

.ag-theme-alpine .ag-header,
.ag-theme-alpine .ag-pinned-right-header,
.ag-theme-alpine .ag-pinned-left-header {
  z-index: 1;
  overflow: visible !important;
}

/* Cell overflow visibility on focus */
.ag-theme-alpine .ag-row-focus {
  z-index: 1;
}

/* Cell focus border */
.ag-theme-alpine .ag-cell-focus,
.ag-theme-alpine .ag-cell {
  border: none !important;
}

/* Overflow block for component going out of last rows for grid */
.ag-theme-alpine .ag-center-cols-viewport {
  overflow: auto;
}

/* Grid cell padding */
.ag-theme-alpine .ag-cell {
  padding-right: 5px;
  padding-left: 5px;
}

.ag-theme-alpine .ag-cell-value {
  line-height: 1.4em;
}

/* Row body mini height */
.ag-theme-alpine .ag-center-cols-clipper {
  min-height: 300px !important;
}

/* ------- Cell Classes ------- */
.hasPopup {
  overflow: visible !important;
}

.no-cell-pad-right {
  padding-right: 0 !important;
}

.center-grid-column-data {
  display: flex;
  align-items: center;
  height: 100%;
}

</style>

<style scoped>
.ag-grid__wrapper {
  overflow: hidden;
}

.grid__pagination-cont {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.grid-left-side__cont {
  display: flex;
}

.grid__items-per-page {
  margin-right: 20px;
}
</style>
