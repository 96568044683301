(<template>
  <section class="default-sort">
    <div class="default-sort__wrapper">
      <p class="default-sort__text">{{ $gettext('Default sort by:') }}</p>
      <sk-select :preselected-value="chosenValue"
                 :items-list="items"
                 class="default-sort__select"
                 @csvaluechanged="changeDefaultSort" />
    </div>
  </section>
</template>)

<script>
  export default {
    data() {
      return {
        items: [
          {id: 'id', name: this.$gettext('ID')},
          {id: 'startTime', name: this.$gettext('Start time')},
          {id: 'createdAt', name: this.$gettext('Created at')}
        ]
      };
    },
    computed: {
      chosenValue() {
        return this.$store.state.defaultSortBy;
      }
    },
    methods: {
      changeDefaultSort(value) {
        this.$store.commit('setDefaultSort', value);
        this.$emit('defaultsortchanged');
      }
    }
  };
</script>

<style>
  .default-sort__select .sk-select {
    padding-right: 15px;
    border: 0;
    text-align: right;
  }

  .default-sort__select .sk-select.is-opened {
    box-shadow: none;
  }

  .default-sort__select .sk-select__value {
    padding: 0;
    line-height: 35px;
  }

  .default-sort__select .sk-select__arrow {
    width: 15px;
    height: 35px;
  }

  .default-sort__select .sk-select__arrow::after {
    right: 2px;
    background-color: #000;
  }

  .default-sort__select .sk-select__arrow::before {
    left: 2px;
    background-color: #000;
  }

  .default-sort__select .sk-select__list {
    top: auto;
    right: 0;
    bottom: 100%;
    left: auto;
    width: auto;
    text-align: start;
  }
</style>

<style scoped>
  .default-sort {
    flex-shrink: 0;
  }

  .default-sort__select {
    width: 80px;
    color: #000;
    font-size: 12px;
  }

  .default-sort__wrapper {
    display: flex;
    align-items: center;
    color: #646d8c;
    font-size: 12px;
  }

  .default-sort__text {
    padding-right: 5px;
    text-align: right;
  }

  @media (max-width: 767px) {
    .default-sort__select {
      margin-bottom: 0;
    }
  }
</style>
